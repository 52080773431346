import {
  BadgeDollarSign,
  CircleUser,
  Home,
  Package2,
  Shirt,
  ShoppingBag,
  ShoppingCart,
  StoreIcon,
  User2,
  Zap,
} from "lucide-react";

export const navItems = [
  {
    icon: <Home className='w-5 h-5' />,
    title: "Dashboard",
    link: "/dashboard",
    active: true,
    roles: ["admin", "manager", "moderator"],
  },
  {
    icon: <ShoppingCart className='w-5 h-5' />,
    title: "Orders",
    link: "/order",
    active: true,
    roles: ["admin", "manager", "moderator"],
  },
  {
    icon: <Shirt className='w-5 h-5' />,
    title: "Products",
    link: "/products",
    active: true,
    roles: ["admin", "manager"],
  },
  {
    icon: <Package2 className='w-5 h-5' />,
    title: "Category",
    link: "/category",
    active: true,
    roles: ["admin", "manager"],
  },
  {
    icon: <Zap className='w-5 h-5' />,
    title: "Campaign",
    link: "/campaign",
    active: true,
    roles: ["admin", "manager"],
  },
  {
    icon: <ShoppingBag className='w-5 h-5' />,
    title: "Purchase Order",
    link: "/purchase-order/list",
    active: true,
    roles: ["admin", "manager"],
  },
  {
    icon: <BadgeDollarSign className='w-5 h-5' />,
    title: "Transactions",
    link: "/transactions",
    active: true,
    roles: ["admin"],
  },
  {
    icon: <StoreIcon className='w-5 h-5' />,
    title: "Stores",
    link: "/stores",
    active: true,
    roles: ["admin", "manager"],
  },
  {
    icon: <CircleUser className='w-5 h-5' />,
    title: "Users",
    link: "/users",
    active: true,
    roles: ["admin"],
  },
  {
    icon: <User2 className='w-5 h-5' />,
    title: "Customers",
    link: "/customers",
    active: false,
    roles: ["admin", "manager"],
  },
];
