import {
  Check,
  CheckCircleIcon,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  File,
  MinusCircleIcon,
  PlusCircle,
  Settings,
  TimerReset,
  Trash2,
  TruckIcon,
  View,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import dayjs from "dayjs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { useOrderList } from "./hooks/useOrderList";
import SingleItem from "./components/SingleOrderItem";
import EmptyView from "../../coreComponents/emptyView";
import { useEffect, useState } from "react";
import { Input } from "../../components/ui/input";
import useDebounce from "../../customHook/useDebounce";
import { IOrder } from "./interface";
import { useNavigate } from "react-router-dom";
import { Separator } from "../../components/ui/separator";
import EditCustomerInformation from "./editOrderCustomer";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../components/ui/sheet";
import useOrder from "./hooks/useOrder";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import CustomAlertDialog from "../../coreComponents/OptionModal";
import useLoginAuth from "../auth/hooks/useLoginAuth";
import {
  generateInvoice,
  generateMultipleInvoicesAndDownloadZip,
} from "../../utils/invoiceGenerator";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../components/ui/drawer";
import UpdateProductData from "./updateProductData";
import { Badge } from "../../components/ui/badge";
import AdjustReturnProduct from "./orderReturn";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { SkeletonCard } from "../../coreComponents/sekeleton";
import SingleItemMobileView from "./components/SingleOrderItemMobileView";

const OrderList = () => {
  const {
    limit,
    refresh,
    setLimit,
    orderFetching,
    orders,
    currentPageNum,
    totalPages,
    bulkOrders,
    totalOrders,
    getOrderList,
    setBulkOrders,
    setSearchQuery,
    updateCurrentPage,
    deleteOrderData,
    selectedStatus,
    setSelectedStatus,
    performOrderBulkUpdate,
  } = useOrderList();

  const { user } = useLoginAuth();

  const { editOrderData, updateOrderStatus } = useOrder();

  const [isCopied, setIsCopied] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [bulkAction, setBulkAction] = useState<string>("");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isReturnProduct, setIsReturnProduct] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
  const [modifyDialogOpen, setModifyDialogOpen] = useState<boolean>(false);

  const debounceHandler = useDebounce(inputValue, 500);

  useEffect(() => {
    if (!!orders && orders.length > 0) setSelectedOrder(orders[0]);
  }, [orders]);

  useEffect(() => {
    setSearchQuery(inputValue);
    //eslint-disable-next-line
  }, [debounceHandler]);

  const handleCopy = () => {
    if (!selectedOrder) return;
    const trackUrl = `https://priorbd.com/order/${selectedOrder?.id}`;
    navigator.clipboard.writeText(trackUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide "Copied!" after 2 seconds
    });
  };

  const navigate = useNavigate();

  const handleGenerateInvoice = async () => {
    if (!!selectedOrder) await generateInvoice(selectedOrder);
  };

  const renderEmptyView = () => {
    return (
      <EmptyView
        title='You have no orders'
        description='You can start selling as soon as you add a product.'
        buttonText='Create Order'
        handleButtonClick={() => navigate("/order/create")}
      />
    );
  };

  const renderStatusButtonView = () => {
    return (
      <div className='mt-2 w-full p-2 rounded-md bg-gray-300 grid grid-cols-3 gap-2'>
        <Button
          variant={selectedStatus === "" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("")}
          className='text-xs md:text-sm'>
          All
        </Button>
        <Button
          variant={selectedStatus === "processing" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("processing")}
          className='text-xs md:text-sm'>
          Processing
        </Button>
        <Button
          variant={selectedStatus === "shipped" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("shipped")}
          className='text-xs md:text-sm'>
          Shipped
        </Button>
        <Button
          variant={selectedStatus === "completed" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("completed")}
          className='text-xs md:text-sm'>
          Completed
        </Button>
        <Button
          variant={selectedStatus === "cancel" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("cancel")}
          className='text-xs md:text-sm'>
          Cancelled
        </Button>
        <Button
          variant={selectedStatus === "return" ? "outline" : "ghost"}
          onClick={() => setSelectedStatus("return")}
          className='text-xs md:text-sm'>
          Return
        </Button>
      </div>
    );
  };

  const renderStatusTabsView = () => {
    return (
      <div className='mt-2'>
        <Tabs
          value={selectedStatus}
          onValueChange={(value: string) => setSelectedStatus(value)}>
          <TabsList>
            <TabsTrigger value='' className='text-xs md:text-sm'>
              All
            </TabsTrigger>
            <TabsTrigger value='processing' className='text-xs md:text-sm'>
              Processing
            </TabsTrigger>
            <TabsTrigger value='shipped' className='text-xs md:text-sm'>
              Shipped
            </TabsTrigger>
            <TabsTrigger value='completed' className='text-xs md:text-sm'>
              Completed
            </TabsTrigger>
            <TabsTrigger value='cancel' className='text-xs md:text-sm'>
              Cancelled
            </TabsTrigger>
            <TabsTrigger value='return' className='text-xs md:text-sm'>
              Return
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
    );
  };

  const renderProductListView = () => {
    return (
      <Tabs defaultValue='all'>
        {drawerDialog()}
        <div className='md:hidden'>{renderStatusButtonView()}</div>
        <div className='hidden md:flex justify-between items-center'>
          {renderStatusTabsView()}
          <Button onClick={() => navigate("/order/create")}>
            Create New Order
          </Button>
        </div>

        <div className='grid grid-1 md:grid-cols-3 md:gap-4'>
          <div
            className={` ${
              !selectedStatus.includes("return") &&
              !!bulkOrders &&
              bulkOrders.length > 0
                ? "md:col-span-2"
                : "md:col-span-3"
            }`}>
            {(!orders || orders.length < 1) && renderEmptyView()}
            {!!orders && orders.length > 0 && (
              <Card
                x-chunk='dashboard-06-chunk-0'
                className='mt-4  w-[92vw] md:mt-4 md:w-full px-0'>
                <CardHeader>
                  <div className='flex flex-col w-full justify-between md:flex-row  '>
                    <div className='md:mr-auto'>
                      <div className='flex md:hidden items-center justify-between'>
                        <CardTitle>Orders</CardTitle>
                        <div className='flex justify-between items-center gap-4'>
                          {["admin", "moderator"].includes(user?.role) && (
                            <Button
                              size='sm'
                              className='h-7 ml-2 '
                              onClick={() => navigate("/order/create")}>
                              <PlusCircle className='h-3.5 w-3.5' />
                              <span className='sr-only sm:not-sr-only sm:whitespace-nowrap'>
                                Create New Order
                              </span>
                            </Button>
                          )}
                          {!selectedStatus.includes("return") &&
                            !!bulkOrders &&
                            bulkOrders.length > 0 &&
                            renderMobileBulkActionPanel()}
                        </div>
                      </div>
                      <CardDescription className='mt-2 hidden md:block'>
                        Manage your orders and view your sales performance.
                      </CardDescription>
                    </div>
                    <div className=' mt-2 md:mt-0 md:ml-auto'>
                      <Input
                        type='text'
                        placeholder='Search'
                        onChange={(event) => {
                          setInputValue(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardContent className='px-2'>
                  <TabsContent value='all'>
                    <div className='max-h-[65vh] overflow-y-auto block md:hidden'>
                      {!!orders &&
                        orders.map((order: IOrder, index: number) => (
                          <SingleItemMobileView
                            key={index}
                            orderNumber={order?.orderNumber}
                            id={`${order?.id}`}
                            paid={order?.paid}
                            status={order?.status}
                            isBulkAdded={bulkOrders.includes(order?.id)}
                            handleBulkCheck={(val: boolean) => {
                              val
                                ? setBulkOrders([...bulkOrders, order?.id])
                                : setBulkOrders(
                                    bulkOrders.filter((b) => b !== order?.id)
                                  );
                            }}
                            district={order?.shipping.district}
                            totalPrice={order?.totalPrice ?? 0}
                            remaining={order?.remaining}
                            customerName={order?.customer?.name}
                            CustomerPhoneNumber={order?.customer?.phoneNumber}
                            handleUpdateOrder={() => {
                              setSelectedOrder(order);
                              setEditDialogOpen(true);
                            }}
                            handleModifyProduct={() => {
                              // setSelectedOrder(order);
                              // setModifyDialogOpen(true);
                              navigate(`/order/modify/${order?.id}`);
                            }}
                            handleReturnProducts={() => {
                              setSelectedOrder(order);
                              setIsReturnProduct(true);
                            }}
                            handleViewDetails={() => {
                              setShowDetails(true);
                              setSelectedOrder(order);
                            }}
                            deleteExistingOrder={deleteOrderData}
                            updatedAt={order?.timestamps?.updatedAt}
                          />
                        ))}
                    </div>
                    <div className='max-h-[65vh] overflow-y-auto hidden md:block'>
                      <Table>
                        <TableHeader className=' sticky  '>
                          <TableRow>
                            {!selectedStatus.includes("return") && (
                              <TableHead>
                                <input
                                  className='border-gray-200 rounded-lg text-primary'
                                  type='checkbox'
                                  onChange={(event) => {
                                    const check = event?.target?.checked;
                                    setBulkOrders(
                                      check
                                        ? orders?.map(
                                            (order: IOrder) => order?.id
                                          )
                                        : []
                                    );
                                  }}
                                />
                              </TableHead>
                            )}
                            <TableHead className='hidden w-[100px] sm:table-cell'>
                              NO.
                            </TableHead>
                            <TableHead>Customer Name</TableHead>
                            <TableHead>Phone Number</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead className='hidden md:table-cell'>
                              District
                            </TableHead>
                            <TableHead>Price</TableHead>
                            <TableHead className='hidden md:table-cell'>
                              Paid
                            </TableHead>
                            <TableHead className='hidden md:table-cell'>
                              Remaining
                            </TableHead>
                            <TableHead className='hidden '>
                              Last Updated at
                            </TableHead>
                            <TableHead>
                              <View />
                            </TableHead>
                            <TableHead>
                              <span className='sr-only'>Actions</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>

                        <TableBody>
                          {!!orders &&
                            orders.map((order: IOrder, index: number) => (
                              <SingleItem
                                key={index}
                                orderNumber={order?.orderNumber}
                                id={`${order?.id}`}
                                paid={order?.paid}
                                status={order?.status}
                                isBulkAdded={bulkOrders.includes(order?.id)}
                                handleBulkCheck={(val: boolean) => {
                                  val
                                    ? setBulkOrders([...bulkOrders, order?.id])
                                    : setBulkOrders(
                                        bulkOrders.filter(
                                          (b) => b !== order?.id
                                        )
                                      );
                                }}
                                district={order?.shipping.district}
                                totalPrice={order?.totalPrice ?? 0}
                                remaining={order?.remaining}
                                customerName={order?.customer?.name}
                                CustomerPhoneNumber={
                                  order?.customer?.phoneNumber
                                }
                                handleUpdateOrder={() => {
                                  setSelectedOrder(order);
                                  setEditDialogOpen(true);
                                }}
                                handleModifyProduct={() => {
                                  // setSelectedOrder(order);
                                  // setModifyDialogOpen(true);
                                  navigate(`/order/modify/${order?.id}`);
                                }}
                                handleReturnProducts={() => {
                                  setSelectedOrder(order);
                                  setIsReturnProduct(true);
                                }}
                                handleViewDetails={() => {
                                  setShowDetails(true);
                                  setSelectedOrder(order);
                                }}
                                deleteExistingOrder={deleteOrderData}
                                updatedAt={order?.timestamps?.updatedAt}
                              />
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                </CardContent>
                {inputValue === "" && (
                  <CardFooter>
                    <div className='w-full flex justify-between items-center'>
                      <div className='text-xs text-muted-foreground'>
                        Showing{" "}
                        <strong>{`${
                          (Number(currentPageNum) - 1) * limit + 1
                        }-${Math.min(
                          Number(currentPageNum) * limit,
                          totalOrders
                        )}`}</strong>{" "}
                        of <strong>{totalOrders}</strong> orders
                      </div>
                      <div className='flex gap-2 items-center'>
                        <Select
                          value={`${limit}`}
                          onValueChange={(value: string) => {
                            setLimit(parseInt(value, 10));
                          }}>
                          <SelectTrigger className='w-auto border-0 md:border'>
                            <SelectValue placeholder='Select Row Limit' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>Limit</SelectLabel>
                              <SelectItem value='10'>10</SelectItem>
                              <SelectItem value='50'>50</SelectItem>
                              <SelectItem value='100'>100</SelectItem>
                              <SelectItem value='150'>150</SelectItem>
                              <SelectItem value='200'>200</SelectItem>
                              <SelectItem value='500'>500</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>{" "}
                        <Button
                          disabled={currentPageNum < 2}
                          variant='outline'
                          size='icon'
                          className='h-7 w-7'
                          onClick={() => updateCurrentPage(-1)}>
                          <ChevronLeft className='h-4 w-4' />
                          <span className='sr-only'>Back</span>
                        </Button>
                        <Button
                          disabled={currentPageNum >= totalPages}
                          variant='outline'
                          size='icon'
                          className='h-7 w-7'
                          onClick={() => updateCurrentPage(1)}>
                          <ChevronRight className='h-4 w-4' />
                          <span className='sr-only'>Next</span>
                        </Button>
                      </div>
                    </div>
                  </CardFooter>
                )}
              </Card>
            )}
          </div>
          {renderOrderDetailsPanel()}
          {!selectedStatus.includes("return") &&
            !!bulkOrders &&
            bulkOrders.length > 0 && (
              <div className='mt-4 hidden md:block'>
                {renderBulkActionPanel()}
              </div>
            )}
        </div>
      </Tabs>
    );
  };

  const renderOrderDetailsPanel = () => {
    return (
      <Sheet
        open={showDetails}
        onOpenChange={(value: boolean) => setShowDetails(value)}
        x-chunk='dashboard-05-chunk-4'>
        <SheetContent className='px-4 pb-2 pt-10 '>
          <SheetHeader className='flex flex-row items-start '>
            <div className='grid gap-0.5 pb-9'>
              <SheetTitle className='group flex items-center gap-2 text-lg w-full'>
                Order #{selectedOrder?.orderNumber}
                {!selectedOrder?.status.includes("return") && (
                  <>
                    {selectedOrder?.status === "processing" && (
                      <CustomAlertDialog
                        title='Order Status Change'
                        description='Are You Sure Change the status to SHIPPED???'
                        cancelButtonText='NO'
                        submitButtonText='YES'
                        onSubmit={() => {
                          updateOrderStatus(
                            `${selectedOrder?.id}`,
                            "shipped",
                            () => refresh()
                          );
                        }}>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                size='icon'
                                variant='outline'
                                className='h-6 w-6 '>
                                <TruckIcon className='h-3 w-3' />
                                <span className='sr-only'>Copy Order ID</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Change Status to shipped
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </CustomAlertDialog>
                    )}
                    {selectedOrder?.status === "shipped" && (
                      <CustomAlertDialog
                        title='Order Status Change'
                        description='Are You Sure Change the status to Complete???'
                        cancelButtonText='NO'
                        submitButtonText='YES'
                        onSubmit={() => {
                          updateOrderStatus(
                            `${selectedOrder?.id}`,
                            "completed",
                            () => refresh()
                          );
                        }}>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                size='icon'
                                variant='outline'
                                className='h-6 w-6 '>
                                <CheckCircleIcon className='h-3 w-3' />
                                <span className='sr-only'>Copy Order ID</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>Complete the order</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </CustomAlertDialog>
                    )}
                    {selectedOrder?.status !== "processing" && (
                      <CustomAlertDialog
                        title='Order Status Change'
                        description='Are You Sure Change the status to Processing??? Use for return or other'
                        cancelButtonText='NO'
                        submitButtonText='YES'
                        onSubmit={() => {
                          updateOrderStatus(
                            `${selectedOrder?.id}`,
                            "processing",
                            () => refresh()
                          );
                        }}>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                size='icon'
                                variant='outline'
                                className='h-6 w-6 '>
                                <TimerReset className='h-3 w-3' />
                                <span className='sr-only'>Copy Order ID</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Reset the order to processing
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </CustomAlertDialog>
                    )}
                    <div className='ml-auto'>
                      {" "}
                      <Button
                        size='sm'
                        variant='outline'
                        className='float-right ml-auto'
                        onClick={() => handleGenerateInvoice()}>
                        Invoice
                      </Button>
                    </div>
                  </>
                )}
              </SheetTitle>
              <SheetDescription>
                <span className='text-sm font-medium text-gray-500 block mt-2 mb-1'>
                  Track Id: {selectedOrder?.id}
                  <button
                    onClick={handleCopy}
                    className='ml-2 relative inline-flex items-center'
                    title='Copy URL'>
                    {isCopied ? (
                      <Check size={18} className='text-green-500' />
                    ) : (
                      <Clipboard
                        size={18}
                        className='text-gray-500 hover:text-gray-700'
                      />
                    )}
                    {isCopied && (
                      <span className='absolute -top-3 left-5 bg-green-100 text-green-700 text-xs font-medium px-2 py-1 rounded'>
                        Copied!
                      </span>
                    )}
                  </button>
                </span>
                Date:{" "}
                {dayjs(selectedOrder?.timestamps.createdAt).format(
                  "MMMM D, YYYY"
                )}
              </SheetDescription>
            </div>
            <div className='ml-auto flex items-center gap-1'>
              {/* <Button size='sm' variant='outline' className='h-8 gap-1'>
              <Truck className='h-3.5 w-3.5' />
              <span className='lg:sr-only xl:not-sr-only xl:whitespace-nowrap'>
                Track Order
              </span>
            </Button> */}
            </div>
          </SheetHeader>
          <div className=' text-sm max-h-[70vh] overflow-y-auto'>
            <div className='grid gap-3'>
              <div className='font-semibold'>Order Details</div>
              <ul className='grid gap-3'>
                {selectedOrder?.products.map((product, index) => (
                  <li key={index} className='flex items-center justify-between'>
                    <Badge variant={"default"} className='bg-gray-600'>
                      {product?.name}{" "}
                      {product?.hasVariation
                        ? `(${`${product?.variation.color}${
                            !!product?.variation?.color &&
                            !!product?.variation?.size
                              ? " - "
                              : ""
                          }${product?.variation?.size}`})`
                        : ""}{" "}
                      x <span>{product?.quantity}</span>
                    </Badge>
                    <span>{product?.totalPrice}</span>
                  </li>
                ))}
              </ul>
              <Separator className='my-2' />
              <ul className='grid gap-3'>
                <li className='flex items-center justify-between'>
                  <span className='text-gray-700'>Subtotal</span>
                  <span>{selectedOrder?.totalPrice}</span>
                </li>

                <li className='flex items-center justify-between'>
                  <span className='text-gray-700'>Delivery Charge</span>
                  <span>{selectedOrder?.deliveryCharge}</span>
                </li>

                <li className='flex items-center justify-between'>
                  <span className='text-gray-700'>Discount</span>
                  <span>{selectedOrder?.discount}</span>
                </li>

                <li className='flex items-center justify-between'>
                  <span className='text-gray-700'>Paid</span>
                  <span>{selectedOrder?.paid}</span>
                </li>
                <li className='flex items-center justify-between font-semibold'>
                  <span className='text-gray-700'>Remaining</span>
                  <span>{selectedOrder?.remaining}</span>
                </li>
              </ul>
            </div>
            <Separator className='my-2' />
            {!!selectedOrder?.notes && (
              <div className='grid grid-cols-1 gap-4'>
                <div className='grid gap-3'>
                  <div className='font-semibold'>Notes</div>
                  <address className='grid gap-0.5 not-italic text-gray-700'>
                    <span>{selectedOrder?.notes ?? ""} </span>
                  </address>
                </div>
              </div>
            )}
            <Separator className='my-2' />
            <div className='grid grid-cols-1 gap-4'>
              <div className='grid gap-3'>
                <div className='font-semibold'>Shipping Information</div>
                <address className='grid gap-0.5 not-italic text-gray-700'>
                  <span>{selectedOrder?.shipping.division}, </span>
                  <span>{selectedOrder?.shipping.district}</span>
                  <span>{selectedOrder?.shipping.address}</span>
                </address>
              </div>
            </div>
            <Separator className='my-2' />
            <div className='grid gap-3'>
              <div className='font-semibold'>Customer Information</div>
              <dl className='grid gap-3'>
                <div className='flex items-center justify-between'>
                  <dt className='text-gray-700'>Customer</dt>
                  <dd>{selectedOrder?.customer.name}</dd>
                </div>
                <div className='flex items-center justify-between'>
                  <dt className='text-gray-700'>Email</dt>
                  <dd>
                    <a href='mailto:'>{selectedOrder?.customer.email}</a>
                  </dd>
                </div>
                <div className='flex items-center justify-between'>
                  <dt className='text-gray-700'>Phone</dt>
                  <dd>
                    <a href='tel:'>{selectedOrder?.customer.phoneNumber}</a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <SheetFooter className='flex flex-row items-center border-t py-3'>
            <div className='text-xs text-gray-700'>
              Updated{" "}
              <time dateTime='2023-11-23'>
                {dayjs(selectedOrder?.timestamps.updatedAt).format(
                  "MMMM D, YYYY"
                )}
              </time>
            </div>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    );
  };

  const renderBulkActionPanel = () => {
    return (
      <Card className='overflow-hidden' x-chunk='dashboard-05-chunk-4'>
        <CardHeader className='flex flex-row items-start bg-muted/50'>
          <div className='grid gap-0.5'>
            <CardTitle className='group flex items-center gap-2 text-lg w-full'>
              Bulk Action
            </CardTitle>
          </div>
          <div className='ml-auto flex items-center gap-1'>
            {/* <Button size='sm' variant='outline' className='h-8 gap-1'>
              <Truck className='h-3.5 w-3.5' />
              <span className='lg:sr-only xl:not-sr-only xl:whitespace-nowrap'>
                Track Order
              </span>
            </Button> */}
          </div>
        </CardHeader>
        <CardContent className='p-6 text-sm'>
          <div className='flex flex-col justify-center items-center gap-4'>
            <div className='w-full grid grid-cols-1 gap-4'>
              <Button
                variant='secondary'
                className='w-full'
                onClick={() => {
                  generateMultipleInvoicesAndDownloadZip(bulkOrders);
                }}>
                {" "}
                <File className='size-5 text-gray-900 mr-2' /> Generate Invoices
              </Button>
            </div>
            <div className='w-full grid grid-cols-3 gap-2'>
              {" "}
              <Button
                variant='default'
                className='w-full bg-blue-700'
                onClick={() => setBulkAction("shipped")}>
                {" "}
                <TruckIcon className='size-5 text-white mr-2' />
                Shipped
              </Button>
              <Button
                variant='default'
                className='w-full bg-green-700'
                onClick={() => setBulkAction("complete")}>
                {" "}
                <CheckCircleIcon className='size-5 text-white mr-2' />
                Complete
              </Button>
              <Button
                variant='default'
                className='w-full'
                onClick={() => setBulkAction("processing")}>
                {" "}
                <TimerReset className='size-5 text-white mr-2' />
                Processing
              </Button>
            </div>
            <div className='w-full grid grid-cols-2 gap-4'>
              <Button
                variant='outline'
                className='w-full'
                onClick={() => setBulkAction("cancel")}>
                {" "}
                <MinusCircleIcon className='size-5 text-red-600 mr-2' /> Cancel
              </Button>
              <Button
                variant='destructive'
                className='w-full'
                onClick={() => setBulkAction("delete")}>
                {" "}
                <Trash2 className='size-5 text-white mr-2' /> Delete
              </Button>
            </div>
          </div>
        </CardContent>
        <CardFooter className='flex flex-row items-center border-t bg-muted/50 px-6 py-3'>
          <div className='text-xs text-muted-foreground'>
            {bulkOrders?.length} of {orders?.length} selected
          </div>
        </CardFooter>
      </Card>
    );
  };

  const renderMobileBulkActionPanel = () => {
    return (
      <Drawer>
        <DrawerTrigger asChild>
          <Button variant='outline' size='sm' className='h-7  '>
            <Settings className='size-5' />
          </Button>
        </DrawerTrigger>
        <DrawerContent className='p-6'>
          <DrawerHeader>
            <DrawerTitle>Bulk Action</DrawerTitle>
          </DrawerHeader>
          <div className='grid grid-cols-2 justify-center items-center gap-4 my-2'>
            <Button
              variant='secondary'
              className='w-full'
              onClick={() => {
                generateMultipleInvoicesAndDownloadZip(bulkOrders);
              }}>
              <File className='size-5 text-gray-900 mr-2' /> Generate Invoices
            </Button>

            <Button
              variant='default'
              className='w-full bg-blue-700'
              onClick={() => setBulkAction("shipped")}>
              <TruckIcon className='size-5 text-white mr-2' /> Shipped
            </Button>
            <Button
              variant='default'
              className='w-full bg-green-700'
              onClick={() => setBulkAction("complete")}>
              <CheckCircleIcon className='size-5 text-white mr-2' /> Complete
            </Button>
            <Button
              variant='default'
              className='w-full'
              onClick={() => setBulkAction("processing")}>
              <TimerReset className='size-5 text-white mr-2' /> Processing
            </Button>

            <Button
              variant='outline'
              className='w-full'
              onClick={() => setBulkAction("cancel")}>
              <MinusCircleIcon className='size-5 text-red-600 mr-2' /> Cancel
            </Button>
            <Button
              variant='destructive'
              className='w-full'
              onClick={() => setBulkAction("delete")}>
              <Trash2 className='size-5 text-white mr-2' /> Delete
            </Button>
          </div>
          <DrawerFooter className='flex flex-row items-center border-t bg-muted/50 px-6 py-3'>
            <div className='text-xs text-muted-foreground'>
              {bulkOrders?.length} of {orders?.length} selected
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  };

  const drawerDialog = () => {
    return (
      <Sheet
        open={isEditDialogOpen}
        onOpenChange={(val) => setEditDialogOpen(val)}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Edit Order</SheetTitle>
            <SheetDescription className='text-red-400'>
              This action cannot be undone.
            </SheetDescription>
          </SheetHeader>
          <br />
          <EditCustomerInformation
            notes={selectedOrder?.notes ?? ""}
            customerInfo={selectedOrder?.customer}
            shipping={selectedOrder?.shipping}
            deliveryCharge={selectedOrder?.deliveryCharge ?? 0}
            totalPrice={selectedOrder?.totalPrice ?? 0}
            paid={selectedOrder?.paid ?? 0}
            remaining={selectedOrder?.remaining ?? 0}
            discount={selectedOrder?.discount ?? 0}
            handleClose={() => setEditDialogOpen(false)}
            handleCustomerDataChange={(data) => {
              editOrderData(
                { ...data, id: selectedOrder?.id },
                (success: boolean) => {
                  if (success) refresh();
                  setEditDialogOpen(false);
                }
              );
            }}
          />
        </SheetContent>
      </Sheet>
    );
  };

  const renderBulkActionDrawer = () => {
    return (
      <Drawer
        open={
          !!bulkOrders &&
          bulkOrders?.length > 0 &&
          ["shipped", "complete", "processing", "delete", "cancel"].includes(
            bulkAction
          )
        }>
        <DrawerContent className='p-20'>
          <DrawerHeader className='mx-auto'>
            <DrawerTitle>Are you absolutely sure?</DrawerTitle>
            <DrawerDescription>This action cannot be undone.</DrawerDescription>
          </DrawerHeader>
          <DrawerFooter className='container'>
            <div className='flex justify-center items-center gap-6'>
              <DrawerClose>
                <Button
                  variant='destructive'
                  onClick={() => {
                    performOrderBulkUpdate(bulkAction);
                    setBulkAction("");
                  }}>
                  I'm Sure
                </Button>
              </DrawerClose>
              <DrawerClose>
                <Button variant='outline' onClick={() => setBulkAction("")}>
                  Cancel
                </Button>
              </DrawerClose>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  };

  const returnModal = () => {
    return (
      <Dialog
        open={isReturnProduct && !!selectedOrder}
        onOpenChange={(open) => setIsReturnProduct(open)}>
        <DialogContent className='sm:max-w-[425px]'>
          <DialogHeader>
            <DialogTitle>Return Product</DialogTitle>
          </DialogHeader>
          <div className='w-auto'>
            <AdjustReturnProduct
              // @ts-ignore
              order={selectedOrder}
              handleClose={() => {
                refresh();
                setIsReturnProduct(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const mainView = () => {
    if (orderFetching) {
      return <SkeletonCard title='Loading Order Data...' />;
    } else if (modifyDialogOpen && !!selectedOrder) {
      return (
        <UpdateProductData
          order={selectedOrder}
          handleBack={() => {
            setModifyDialogOpen(false);
            setSelectedOrder(null);
            getOrderList();
          }}
        />
      );
    } else {
      return renderProductListView();
    }
  };

  return (
    <div className='w-full sm:w-[95vw]'>
      {mainView()}
      {renderBulkActionDrawer()}
      {returnModal()}
    </div>
  );
};

export default OrderList;
